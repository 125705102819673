function Footer() {
  const footerStyle = {
    backgroundColor: "var(--color-primary)",
    width: "100%",
    height: "4rem",
    textAlign: "center",
    textJustify: "center",
    color: "#ffffff",
    fontFamily: "EB Garamond",
    fontstyle: "normal",
    fontweight: "400",
    fontSize: "1.6rem",
    lineHeight: "4rem",
    marginTop: "2rem",
    position: "relative",
    bottom: 0,
  };
  return (
    <div className="footer" style={footerStyle}>
      <div className="copyright">
        © 2022 <span style={{ color: "#A7CF57" }}>Budylon</span>, All Right Reversed.
      </div>
    </div>
  );
}
export default Footer;
