import Footer from "./Footer";
import { useRef, useEffect} from "react";
import MiddleIcon from "./MiddleIcon";
import "./Homepage.scss"
import { useTranslation } from 'react-i18next';

function Homepage() {
  const logoWithNameRef = useRef(null);
  const companyNameRef = useRef(null);
  const text11Ref = useRef(null);
  const text12Ref = useRef(null);
  const text21Ref = useRef(null);
  const text22Ref = useRef(null);
  const text31Ref = useRef(null);
  const text32Ref = useRef(null);
  const text41Ref = useRef(null);
  const text42Ref = useRef(null);
  const companyNameFinishedRef = useRef(null);
  const sloganRef = useRef(null);
  const { t } = useTranslation();

  function handleScroll() {
    let scroll = window.pageYOffset / (document.body.offsetHeight - window.innerHeight);
    // document.body.style.setProperty("--scroll", scroll);
    if (scroll === 0) {
      companyNameRef.current.style.opacity = 1;
    } else if (scroll > 0 && scroll <= 0.1) {
      logoWithNameRef.current.style.opacity = (scroll * 10 - 1) * -1;
      companyNameRef.current.style.opacity = (scroll * 10 - 1) * -1;
      text11Ref.current.style.opacity = 0;
      text12Ref.current.style.opacity = 0;
    } else if (scroll > 0.1 && scroll <= 0.2) {
      logoWithNameRef.current.style.opacity = 0;
      companyNameRef.current.style.opacity = 0;
      text11Ref.current.style.opacity = scroll * 10 - 1;
      text12Ref.current.style.opacity = scroll * 10 - 1;
    } else if (scroll > 0.2 && scroll <= 0.3) {
      text11Ref.current.style.opacity = (scroll * 10 - 3) * -1;
      text12Ref.current.style.opacity = (scroll * 10 - 3) * -1;
      const normalized = scroll * 10 - 2;
      text11Ref.current.style.transform = `translate(calc(-60% - ${normalized} * 5vw),calc(-50% - 15vh - ${normalized} * 30vh))`;
      text12Ref.current.style.transform = `translate(calc(60% + ${normalized} * 5vw),calc(-50% + 15vh + ${normalized} * 30vh))`;
      text21Ref.current.style.opacity = 0;
      text22Ref.current.style.opacity = 0;
    } else if (scroll > 0.3 && scroll <= 0.4) {
      text11Ref.current.style.opacity = 0;
      text12Ref.current.style.opacity = 0;
      text21Ref.current.style.opacity = scroll * 10 - 3;
      text22Ref.current.style.opacity = scroll * 10 - 3;
    } else if (scroll > 0.4 && scroll <= 0.5) {
      text21Ref.current.style.opacity = (scroll * 10 - 5) * -1;
      text22Ref.current.style.opacity = (scroll * 10 - 5) * -1;
      const normalized = scroll * 10 - 4;
      text21Ref.current.style.transform = `translate(calc(-60% - ${normalized} * 5vw),calc(-50% - 15vh - ${normalized} * 30vh))`;
      text22Ref.current.style.transform = `translate(calc(60% + ${normalized} * 5vw),calc(-50% + 15vh + ${normalized} * 30vh))`;
      text31Ref.current.style.opacity = 0;
      text32Ref.current.style.opacity = 0;
    } else if (scroll > 0.5 && scroll <= 0.6) {
      text21Ref.current.style.opacity = 0;
      text22Ref.current.style.opacity = 0;
      text31Ref.current.style.opacity = scroll * 10 - 5;
      text32Ref.current.style.opacity = scroll * 10 - 5;
    } else if (scroll > 0.6 && scroll <= 0.7) {
      text31Ref.current.style.opacity = (scroll * 10 - 7) * -1;
      text32Ref.current.style.opacity = (scroll * 10 - 7) * -1;
      const normalized = scroll * 10 - 6;
      text31Ref.current.style.transform = `translate(calc(-60% - ${normalized} * 5vw),calc(-50% - 15vh - ${normalized} * 30vh))`;
      text32Ref.current.style.transform = `translate(calc(60% + ${normalized} * 5vw),calc(-50% + 15vh + ${normalized} * 30vh))`;
      text41Ref.current.style.opacity = 0;
      text42Ref.current.style.opacity = 0;
    } else if (scroll > 0.7 && scroll <= 0.8) {
      text31Ref.current.style.opacity = 0;
      text32Ref.current.style.opacity = 0;
      text41Ref.current.style.opacity = scroll * 10 - 7;
      text42Ref.current.style.opacity = scroll * 10 - 7;
      companyNameFinishedRef.current.style.opacity = 0;
    } else if (scroll > 0.8 && scroll <= 0.9) {
      text41Ref.current.style.opacity = (scroll * 10 - 9) * -1;
      text42Ref.current.style.opacity = (scroll * 10 - 9) * -1;
      const normalized = scroll * 10 - 8;
      text41Ref.current.style.transform = `translate(calc(-60% - ${normalized} * 5vw),calc(-50% - 15vh - ${normalized} * 30vh))`;
      text42Ref.current.style.transform = `translate(calc(60% + ${normalized} * 5vw),calc(-50% + 15vh + ${normalized} * 30vh))`;
      companyNameFinishedRef.current.style.opacity = (scroll * 10 - 8);
    }else if (scroll > 0.9 && scroll < 1) {
      companyNameFinishedRef.current.style.opacity = 1;
      text41Ref.current.style.opacity = 0;
      text42Ref.current.style.opacity = 0;
      const normalized = scroll * 10 - 9;
      companyNameFinishedRef.current.style.transform = `translate(0px ,calc(-50% - ${normalized} * 50%))`;
      // sloganRef.current.style.top = `calc(110vh - ${normalized} * 60vh)`;
      sloganRef.current.style.bottom = `calc(-10vh + ${normalized} * 55vh)`;
      sloganRef.current.style.fontSize = `calc(4.8rem - ${normalized} * 1.6rem)`;
    } else if (scroll === 1){
      sloganRef.current.style.fontSize = "3.2rem";
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener(
      "scroll",
      handleScroll,
      false
    );
  }, []);
  useEffect( () => () => {
    window.removeEventListener("scroll",handleScroll);
  }, [] );
  return (
    <div className="homepage" style={{width: "100%"}}>
      <div className="top-shader" />
      <div className="logo-with-name logo-with-name-fadeout" ref={logoWithNameRef}>
        <div className="name-left logo-name">Bud</div>
        <svg width="72" height="104" viewBox="0 0 28 40" fill="none" xmlns="http://www.w3.org/2000/svg" className="logo-svg" overflow="visible">
          <path
            id="logo-path-9"
            className="logo-path"
            d="M14.3682 34.5876V39.664C14.3685 39.7302 14.3886 39.7948 14.4259 39.8497C14.4632 39.9046 14.516 39.9473 14.5778 39.9726C14.6395 39.9979 14.7074 40.0046 14.7729 39.9917C14.8385 39.9789 14.8987 39.9472 14.9461 39.9006L20.3006 34.5876H14.3682Z"
            fill="#143E20"
          />
          <path
            id="logo-path-8"
            className="logo-path"
            d="M7.69922 34.5876L13.0609 39.9006C13.1083 39.9472 13.1685 39.9789 13.2341 39.9917C13.2996 40.0046 13.3675 39.9979 13.4292 39.9726C13.4909 39.9473 13.5438 39.9046 13.5811 39.8497C13.6183 39.7948 13.6384 39.7302 13.6388 39.664V34.5876H7.69922Z"
            fill="#143E20"
          />
          <path
            id="logo-path-7"
            className="logo-path"
            d="M13.6364 33.873H20.2997L14.0018 27.6323L13.6436 27.9873V27.9826L7.69922 33.873H13.6364Z"
            fill="#246835"
          />
          <path id="logo-path-6" className="logo-path" d="M14.8857 27.4856L21.1931 33.7357L27.4982 27.4856H14.8857Z" fill="#25983A" />
          <path id="logo-path-5" className="logo-path" d="M7.18164 20.8733V33.376L13.489 27.1234L7.18164 20.8733Z" fill="#25983A" />
          <path id="logo-path-4" className="logo-path" d="M0 26.9718L6.45786 33.3711V20.1561L0 13.7568V26.9718Z" fill="#6FBA2C" />
          <path id="logo-path-3" className="logo-path" d="M14.8809 26.7638H27.9996V13.7642L14.8809 26.7638Z" fill="#6FBA2C" />
          <path
            id="logo-path-2"
            className="logo-path"
            d="M14.3604 0.336081V26.2691L27.854 12.9003L14.9383 0.0994226C14.8909 0.0528119 14.8306 0.0211144 14.7651 0.00830607C14.6996 -0.00450228 14.6317 0.0021489 14.57 0.0274253C14.5082 0.0527017 14.4554 0.0954777 14.4181 0.150388C14.3808 0.205298 14.3607 0.269897 14.3604 0.336081Z"
            fill="#C5DA53"
          />
          <path
            id="logo-path-1"
            className="logo-path"
            d="M6.46539 19.1457L13.6445 26.2691V0.336081C13.6441 0.269897 13.624 0.205298 13.5867 0.150388C13.5495 0.0954777 13.4966 0.0527017 13.4349 0.0274253C13.3732 0.0021489 13.3053 -0.00450228 13.2398 0.00830607C13.1742 0.0211144 13.114 0.0528119 13.0666 0.0994226L0.148438 12.9003L6.46539 19.1694V19.1457Z"
            fill="#A7CF57"
          />
        </svg>
        <div className="name-right logo-name">ylon</div>
      </div>
      <div className="company-name" ref={companyNameRef} style={{fontFamily:"var(--zh-TW-font-family)"}}>
        權輿科技
      </div>
      <div className="middle-shader" />
      <MiddleIcon />
      <div className="text-1-1 middle-text middle-text-left" ref={text11Ref}>
        {t("homepage.sec1.leftText")}
      </div>
      <div className="text-1-2 middle-text middle-text-right" ref={text12Ref}>
        {t("homepage.sec1.rightText")}
      </div>
      <div className="text-2-1 middle-text middle-text-left" ref={text21Ref} style={{ transform: "translate(-60%, calc(-50% - 15vh))" }}>
        {t("homepage.sec2.leftText")}
      </div>
      <div className="text-2-2 middle-text middle-text-right" ref={text22Ref} style={{ transform: "translate(60%, calc(-50% + 15vh))" }}>
        {t("homepage.sec2.rightText")}
      </div>
      <div className="text-3-1 middle-text middle-text-left" ref={text31Ref} style={{ transform: "translate(-60%, calc(-50% - 15vh))" }}>
        {t("homepage.sec3.leftText")}
      </div>
      <div className="text-3-2 middle-text middle-text-right" ref={text32Ref} style={{ transform: "translate(60%, calc(-50% + 15vh))" }}>
        {t("homepage.sec3.rightText")}
      </div>
      <div className="text-4-1 middle-text middle-text-left" ref={text41Ref} style={{ transform: "translate(-60%, calc(-50% - 15vh))" }}>
        {t("homepage.sec4.leftText")}
      </div>
      <div className="text-4-2 middle-text middle-text-right" ref={text42Ref} style={{ transform: "translate(60%, calc(-50% + 15vh))" }}>
        {t("homepage.sec4.rightText")}
      </div>
      <div className="company-name-finished" ref={companyNameFinishedRef}>
        {t('companyName')}
      </div>
      <div className="slogan" ref={sloganRef}>
        {t('slogan')}
      </div>

      <div className="content-container">
        <div className="gradient-line" />
        <div className="sec-1-line-1 deco-line" style={{ top: "110vh", left: "80%", background: "#AB6321", width: "6px", height: "30vh" }} />
        <div className="sec-1-line-2 deco-line" style={{ top: "160vh", left: "30%", background: "#E09E61", width: "8px", height: "21vh" }} />
        <div className="sec-1-line-3 deco-line" style={{ top: "170vh", left: "20%", background: "#D67C29", width: "4px", height: "16vh" }} />

        <div className="sec-2-line-1 deco-line" style={{ top: "305vh", left: "80%", background: "#3383CC", width: "6px", height: "30vh" }} />
        <div className="sec-2-line-2 deco-line" style={{ top: "340vh", left: "20%", background: "#92BDE4", width: "8px", height: "28vh" }} />
        <div className="sec-2-line-3 deco-line" style={{ top: "360vh", left: "75%", background: "#5C9BD6", width: "4px", height: "16vh" }} />
        <div className="sec-2-line-4 deco-line" style={{ top: "370vh", left: "60%", background: "#ADCDEB", width: "4px", height: "10vh" }} />

        <div className="sec-3-line-1 deco-line" style={{ top: "495vh", left: "60%", background: "#E1CE20", width: "4px", height: "8vh" }} />
        <div className="sec-3-line-2 deco-line" style={{ top: "504vh", left: "25%", background: "#EDE178", width: "4px", height: "18vh" }} />
        <div className="sec-3-line-3 deco-line" style={{ top: "520vh", left: "90%", background: "#F3EBA5", width: "6px", height: "26vh" }} />
        <div className="sec-3-line-4 deco-line" style={{ top: "534vh", left: "10%", background: "#B4A518", width: "8px", height: "34vh" }} />
        <div className="sec-3-line-5 deco-line" style={{ top: "558vh", left: "78%", background: "#E7D84B", width: "4px", height: "20vh" }} />

        <div className="sec-4-line-1 deco-line" style={{ top: "680vh", left: "60%", background: "#D63511", width: "4px", height: "8vh" }} />
        <div className="sec-4-line-2 deco-line" style={{ top: "703vh", left: "25%", background: "#F58870", width: "4px", height: "18vh" }} />
        <div className="sec-4-line-3 deco-line" style={{ top: "710vh", left: "90%", background: "#F06242", width: "6px", height: "26vh" }} />
        <div className="sec-4-line-4 deco-line" style={{ top: "725vh", left: "10%", background: "#8F220A", width: "8px", height: "34vh" }} />
        <div className="sec-4-line-5 deco-line" style={{ top: "742vh", left: "78%", background: "#F06242", width: "4px", height: "20vh" }} />
        <div className="sec-4-line-6 deco-line" style={{ top: "721vh", left: "63%", background: "#D63511", width: "4px", height: "15vh" }} />
        <img src="/bud.png" alt="bud" style={{ display: "block", width: "4rem", height: "4rem", margin: "0px auto" }}></img>
        <Footer />
      </div>
    </div>
  );
}

export default Homepage;