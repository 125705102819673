import "./MiddleIcon.scss";
import { useEffect, useRef } from "react";

function MiddleIcon() {
  const circularArrowPeopleRef = useRef(null);
  const peopleRef = useRef(null);
  const gearRef = useRef(null);
  const gearImageref = useRef(null);
  const handRef = useRef(null);
  const circularArrowHandRef = useRef(null);
  const objectRef = useRef(null);
  const circularArrowObjectRef = useRef(null);
  function handleScroll() {
    let scroll = window.pageYOffset / (document.body.offsetHeight - window.innerHeight);
    if (scroll === 0) {
      peopleRef.current.style.opacity = 0;
    } else if (scroll > 0 && scroll <= 0.1) {
      circularArrowPeopleRef.current.style.transform = null;
      peopleRef.current.style.opacity = scroll * 10;
    } else if (scroll > 0.1 && scroll <= 0.2) {
      const degree = Math.round((scroll * 10 - 1) * 180 * 100) / 100;
      circularArrowPeopleRef.current.style.transform = `rotate(${degree}deg)`;
      gearRef.current.style.opacity = 0;
    } else if (scroll > 0.2 && scroll <= 0.3) {
      peopleRef.current.style.opacity = (scroll * 10 - 3) * -1;
      circularArrowPeopleRef.current.style.transform = null;
      gearRef.current.style.opacity = scroll * 10 - 2;
      gearImageref.current.style.transform = null;
    } else if (scroll > 0.3 && scroll <= 0.4) {
      peopleRef.current.style.opacity = 0;
      const degree = Math.round((scroll * 10 - 3) * 180 * 100) / 100;
      gearImageref.current.style.transform = `rotate(${degree}deg)`;
      handRef.current.style.opacity = 0;
    } else if (scroll > 0.4 && scroll <= 0.5) {
      gearRef.current.style.opacity = (scroll * 10 - 5) * -1;
      gearImageref.current.style.transform = null;
      handRef.current.style.opacity = scroll * 10 - 4;
      circularArrowHandRef.current.style.transform = null;
    } else if (scroll > 0.5 && scroll <= 0.6) {
      gearRef.current.style.opacity = 0;
      const degree = Math.round((scroll * 10 - 5) * 180 * 100) / 100;
      circularArrowHandRef.current.style.transform = `rotate(${degree}deg)`;
      objectRef.current.style.opacity = 0;
    } else if (scroll > 0.6 && scroll <= 0.7) {
      handRef.current.style.opacity = (scroll * 10 - 7) * -1;
      circularArrowHandRef.current.style.transform = null;
      objectRef.current.style.opacity = scroll * 10 - 6;
      circularArrowObjectRef.current.style.transform = null;
    } else if (scroll > 0.7 && scroll <= 0.8) {
      handRef.current.style.opacity = 0;
      const degree = Math.round((scroll * 10 - 5) * 180 * 100) / 100;
      circularArrowObjectRef.current.style.transform = `rotate(${degree}deg)`;
    } else if (scroll > 0.8 && scroll <= 0.9) {
      objectRef.current.style.opacity = (scroll * 10 - 9) * -1;
      circularArrowObjectRef.current.style.transform = null;
    } else if (scroll > 0.9 && scroll <= 1) {
      objectRef.current.style.opacity = 0;
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener(
      "scroll",
      handleScroll,
      false
    );
  }, []);
  useEffect( () => () => {
    window.removeEventListener("scroll",handleScroll);
  }, [] );
  return (
    <>
      <div className="middle-icon people" ref={peopleRef}>
        <img src="/Circular-Arrow.png" className="middle-icon-image circular-arrow-people" alt="icon" ref={circularArrowPeopleRef}></img>
        <img src="/People.png" className="middle-icon-image" alt="icon"></img>
      </div>
      <div className="middle-icon gear" ref={gearRef} style={{ width: "8rem", height: "8rem" }}>
        <img src="/Gear.png" className="middle-icon-image" alt="icon" ref={gearImageref}></img>
      </div>
      <div className="middle-icon hand" ref={handRef}>
        <img src="/Circular-Arrow.png" className="middle-icon-image circular-arrow-hand" alt="icon" ref={circularArrowHandRef}></img>
        <img src="/hand.png" className="middle-icon-image" alt="icon"></img>
      </div>
      <div className="middle-icon object" ref={objectRef}>
        <img src="/Circular-Arrow.png" className="middle-icon-image circular-arrow-object" alt="icon" ref={circularArrowObjectRef}></img>
        <img src="/Object.png" className="middle-icon-image" alt="icon"></img>
      </div>
    </>
  );
}
export default MiddleIcon;
