import AppBar from "./AppBar";
import Footer from "./Footer";
import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import "./Contact.scss";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { useForm } from "react-hook-form";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAFjQjeTD1cmpLbdnAtaE3eOE2VBXB08fc",
  authDomain: "official-website-927be.firebaseapp.com",
  projectId: "official-website-927be",
  storageBucket: "official-website-927be.appspot.com",
  messagingSenderId: "650293843729",
  appId: "1:650293843729:web:0914c516a07fafd4335eb0",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
//在本機測試時必須要設定
// window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
// const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaV3Provider("6LerTaYgAAAAAFuonVkevnnEcAr9UElL-D9dw1tC"),
//   // Optional argument. If true, the SDK automatically refreshes App Check
//   // tokens as needed.
//   isTokenAutoRefreshEnabled: true,
// });
const db = getFirestore(app);

const CssTextField = styled(TextField)({
  "& label": {
    fontFamily: "var(--en-font-family)",
    fontWeight: "600",
    fontSize: "1.6rem",
  },
  "& .MuiInput-underline:after": {},
  "& .MuiOutlinedInput-root": {
    borderRadius: "0px",
    fontFamily: "var(--main-font-family)",
    fontSize: "1.6rem",
    backgroundColor: "white",
    "& input": {
      //   backgroundColor: "white",
    },
    "& textarea": {
      //   backgroundColor: "white",
    },
    "&:hover fieldset": {
      //   borderColor: "var(--color-primary)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--color-primary)",
    },
  },
});
const ColorButton = styled(Button)(({ theme }) => ({
  backgroundColor: "var(--color-foreground)",
  boxShadow: "none",
  alignSelf: "center",
  borderRadius: "0px",
  color: "var(--color-primary)",
  fontSize: "2rem",
  fontFamily: "var(--main-font-family)",
  "&:hover": {
    backgroundColor: "#b2c44b",
    boxShadow: "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
  },
  "&:active": {
    backgroundColor: "#b2c44b",
    boxShadow: "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
  },
}));
function Contact() {
  const [open, setOpen] = useState(false);
  const [confirmMsg, setConfirmMsg] = useState("");
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    console.log(data);
    const emailResponseTemplate = `<h2>您好 ${data.name}</h2>感謝您的留言，我們會盡快回覆。<br/><br/>---<br/>權輿科技 Budylon<hr>${data.message}`;
    try {
      const toSupportDocRef = await addDoc(collection(db, "mail"), {
        to: ["support@budylon.com"],
        message: {
          html: data.message,
          subject: "客戶諮詢: " + data.name + " " + data.email,
          text: data.message,
        },
      });
      // console.log("To support document written with ID: ", toSupportDocRef.id);
      const toClientDocRef = await addDoc(collection(db, "mail"), {
        to: [data.email],
        message: {
          html: emailResponseTemplate,
          subject: "權輿科技留言確認",
          text: data.message,
        },
      });
      // console.log("To client document written with ID: ", toClientDocRef.id);
      setConfirmMsg("已寄送留言確認信到您的信箱，感謝您的留言");
      setOpen(true);
    } catch (e) {
      console.error("Error adding document: ", e);
      setConfirmMsg("很抱歉，傳送留言失敗，請使用support@budylon.com聯絡我們");
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    const load = async () => {
      let { initializeAppCheck, ReCaptchaV3Provider } = await import("firebase/app-check");
      const appCheck = initializeAppCheck(app, {
        provider: new ReCaptchaV3Provider("6LerTaYgAAAAAFuonVkevnnEcAr9UElL-D9dw1tC"),
        // Optional argument. If true, the SDK automatically refreshes App Check
        // tokens as needed.
        isTokenAutoRefreshEnabled: true,
      });
    };
    load();
  }, []);
  return (
    <>
      <Helmet>
        {/* <title>權輿科技 Budylon - 聯絡我們</title> */}
        <link rel="canonical" href="http://budylon.com/contact" />
        <meta name="description" content="權輿科技" />
      </Helmet>
      <div className="grid-container">
        <div className="bg" style={{ zIndex: -1 }}></div>
        <div className="main-content" style={{ height: "100vh" }}>
          <AppBar></AppBar>
          <h1 className="title-bar">{t("contact.title")}</h1>
          <div className="sub-main-content" style={{ flexGrow: 1 }}>
            <div style={{ fontSize: "2.0rem", fontFamily: "var(--en-font-family)", fontWeight: "400", textAlign: "center" }}>support@budylon.com</div>
            <div className="divider" />
            <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="name-label form-label">{t("contact.nameLabel")}</div>
              <CssTextField label="Name" variant="outlined" inputProps={{ ...register("name", { required: true, maxLength: 5 }) }} />
              {/* {errors.name && <span className="error-message">名稱必須填寫</span>} */}
              {errors.name?.type === "required" && <span className="error-message">名稱必須填寫</span>}
              <div className="email-label form-label">E-mail</div>
              <CssTextField
                label="E-mail"
                variant="outlined"
                inputProps={{
                  ...register("email", { required: true, pattern: /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/ }),
                }}
              />
              {errors.email?.type === "required" && <span className="error-message">Email必須填寫</span>}
              {errors.email?.type === "pattern" && <span className="error-message">Email格式錯誤</span>}
              <div className="message-label form-label">{t("contact.messageLabel")}</div>
              <CssTextField label="Message" variant="outlined" multiline minRows={4} inputProps={{ ...register("message", { required: true }) }} />
              {errors.message?.type === "required" && <span className="error-message">訊息必須填寫</span>}
              <ColorButton type="submit" sx={{ width: "120px", height: "40px" }}>
                {t("contact.sendBtn")}
              </ColorButton>
            </form>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              PaperProps={{ sx: { backgroundColor: "var(--color-background)", borderRadius: "0px" } }}
            >
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  sx={{ fontFamily: "var(--main-font-family)", fontSize: "1.6rem", color: "var(--color-primary)" }}
                >
                  {confirmMsg}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <ColorButton
                  onClick={handleClose}
                  autoFocus
                  sx={{ fontFamily: "var(--main-font-family)", fontSize: "1.6rem", color: "var(--color-primary)" }}
                >
                  確認
                </ColorButton>
              </DialogActions>
            </Dialog>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Contact;
