import logo from "./logo.svg";
import "./AppBar.css";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { useState } from "react";
import {  useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ColorButton = styled(Button)(({ theme }) => ({
  // width: "120px",
  color: "var(--color-primary)",
  fontSize: "2.0rem",
  fontFamily: "var(--main-font-family)",
  zIndex: "9",
  "&:hover": {
    backgroundColor: "rgba(25, 118, 210, 0.1)",
    borderRadius: "0px",
  },
}));
const AppBarMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    sx={{
      backgroundColor: "rgba(226, 226, 226, 0.8)",
      top: "6rem",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 0,
    // marginTop: theme.spacing(1),
    marginLeft: "0px",
    width: "100vw",
    maxWidth: "100vw",
    left: "0px !important",
    // right: "0px !important",
    top: "6rem !important",
    position: "fixed",
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
      backgroundColor: "var(--color-primary)",
    },
    "& .MuiMenuItem-root": {
      color: "var(--color-on-primary)",
      fontSize: "2.0rem",
      fontFamily: "var(--main-font-family)",
      fontweight: "400",
      justifyContent: "center",
    },
  },
}));
function MenuDivider() {
  return (
    <Divider
      sx={{
        color: "var(--color-background)",
        borderColor: "var(--color-background)",
        width: "90vw",
        margin: "0 auto",
      }}
    />
  );
}
function AppBar() {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { search } = useLocation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    // setAnchorEl(this);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className="appbar-top-shader" />
      <div className="app-bar">
        <Link to={`/${search}`} aria-label="homepage">
          <img src={logo} className="app-logo" alt="budylon logo" ></img>
        </Link>
        <Link to={`/${search}`} aria-label="homepage" style={{ textDecoration: "none" }}>
          <div className="bar-logo-name">Budylon</div>
        </Link>
        <div className="horizontal-placeholder"></div>
        <IconButton
          className="menu-button"
          aria-label="menu"
          sx={{ color: "var(--color-primary)", width: "40px", height: "40px", padding: "4px" }}
          onClick={handleClick}
        >
          <MenuIcon fontSize="inherit" sx={{ height: "25px", width: "25px" }} />
        </IconButton>
        <AppBarMenu id="app-bar-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem
            onClick={() => {
              handleClose();
            }}
          >
            <Link className="menu-anchor" to={`/about${search}`}>
              {t("appbar.about")}
            </Link>
          </MenuItem>
          <MenuDivider />
          <MenuItem
            onClick={() => {
              handleClose();
              // navigate(`/portfolio${search}`);
            }}
          >
            <Link className="menu-anchor" to={`/portfolio${search}`}>
              {t("appbar.portfolio")}
            </Link>
          </MenuItem>
          <MenuDivider />
          <MenuItem
            onClick={() => {
              handleClose();
            }}
          >
            <Link className="menu-anchor" to={`/contact${search}`}>
              {t("appbar.contact")}
            </Link>
          </MenuItem>
        </AppBarMenu>
        <ColorButton className="app-bar-button" href={`/about${search}`}>
          {t("appbar.about")}
        </ColorButton>
        <ColorButton className="app-bar-button" href={`/portfolio${search}`}>
          {t("appbar.portfolio")}
        </ColorButton>
        <ColorButton className="app-bar-button" href={`/contact${search}`}>
          {t("appbar.contact")}
        </ColorButton>
      </div>
    </>
  );
}

export default AppBar;
