import "./App.css";
import AppBar from "./AppBar";
import Homepage from "./Homepage";

function App() {
  return (
    <div className="grid-container">
      <div className="bg" style={{zIndex: 2}}></div>
      <div className="main-content">
        <AppBar></AppBar>
        <Homepage />
      </div>
    </div>
  );
}

export default App;
