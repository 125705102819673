import AppBar from "./AppBar";
import Footer from "./Footer";
import "./AboutPage.scss";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

function AboutPage(props) {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>權輿科技 Budylon - 關於我們</title>
        <link rel="canonical" href="http://budylon.com/about" />
        <meta name="description" content="權輿科技" />
      </Helmet>
      <div className="grid-container">
        <div className="bg" style={{ zIndex: -1 }}></div>
        <div className="main-content">
          <AppBar></AppBar>
          <h1 className="title-bar">{t("companyName")}</h1>
          <div className="sub-main-content">
            <div className="about-sec sec-container">
              <div className="sec-title">關於我們</div>
              <div className="sec-detail">
                權輿科技由兩位對技術深具熱忱的軟體工程師在2022年創立。公司名稱涵義為「萌芽」、「開始」，希望權輿科技與客戶都能透過我們創造的軟體持續成長茁壯，藉由數位科技的輔助達到所期盼的目標。
              </div>
            </div>
            <div className="divider" />
            <div className="idea-sec sec-container">
              <div className="sec-title">理念</div>
              <div className="sec-detail">
                <span style={{ color: "var(--color-accent)" }}>▶</span> 以人為本的服務
                我們深信軟體是為人服務，以客戶使用經驗為出發點設計出專業、易用的軟體。
                <br />
                <span style={{ color: "var(--color-accent)" }}>▶</span> 以人為本的服務
                好的設計需要經過充分的溝通，協助客戶理清業務邏輯並轉化為符合使用者經驗的軟體是我們的使命，並提供現代化的介面設計，滿足美感與易用需求。
              </div>
            </div>
            <div className="divider" />
            <div className="profession-sec sec-container">
              <div className="sec-title">專業</div>
              <img
                src="/Programming-Icon.png"
                alt="programming-icon"
                style={{ display: "block", maxWidth: "400px", height: "auto", margin: "0px auto", width: "100%" }}
              />
              <div className="sec-detail">
                使用語言、工具為C/C++、Java、Javascript、Qt、ReactJS、Golang、Spring Boot 可提供服務：
                <br />
                <span style={{ color: "var(--color-accent)" }}>▶</span> 桌面視窗軟體：包含Windows和Linux作業系統的視窗軟體，使用Qt
                Framework。Qt使用C++撰寫，提供符合現代化介面、對原生系統API的高度支援，包含網路通訊、序列埠通訊、GPU硬體加速、底層硬體存取等功能，可完成高效能、多功能的桌面軟體。
                <br />
                <span style={{ color: "var(--color-accent)" }}>▶</span> 後端伺服器：使用Java和Golang。Java為後端主流程式語言，使用Spring Boot。Spring
                Boot為現代化的Java框架，特別適用於開發 Web
                應用程式和微服務。Golang為新興的後端語言，提供HTTP的原生支援，用於撰寫高速、高效率的後端程式。
                <br />
                <span style={{ color: "var(--color-accent)" }}>▶</span>{" "}
                前端網頁設計：使用Javascript的框架ReactJS。ReactJS為熱門的前端技術，適用於創建各種類型的網頁程式。
                <br />
                <span style={{ color: "var(--color-accent)" }}>▶</span> 雲端伺服器架設、管理：協助架設Google Cloud Platform和Firebase雲端空間。
              </div>
            </div>
            <div className="divider" />
            <div className="domain-sec sec-container">
              <div className="sec-title">專業</div>
              <div className="sec-detail">
                曾參與專案：
                <br />
                <span style={{ color: "var(--color-accent)" }}>▶</span> 外匯交易平台
                <br />
                <span style={{ color: "var(--color-accent)" }}>▶</span> 大樓消防系統
                <br />
                <span style={{ color: "var(--color-accent)" }}>▶</span> 交通售票系統
                <br />
                <span style={{ color: "var(--color-accent)" }}>▶</span> 牙齒美白機台
                <br />
                並持續接觸不同產業
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default AboutPage;
