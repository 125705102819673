import AppBar from "./AppBar";
import Footer from "./Footer";
import { useEffect } from "react";
import YoutubeEmbed from "./YoutubeEmbed";
import { useTranslation } from "react-i18next";

function Portfolio() {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Budylon 權輿科技 作品集";
  }, []);
  return (
    <>
      <div className="grid-container">
        <div className="bg" style={{ zIndex: -1 }}></div>
        <div className="main-content">
          <AppBar></AppBar>
          <h1 className="title-bar">{t("portfolio.title")}</h1>
          <div className="sub-main-content">
            <div className="fire-sec sec-container">
              <div className="sec-title">{t("portfolio.fireSystem")}</div>
              <img
                src="/floor-inspect.png"
                alt="floor-inspect"
                style={{ display: "block", maxWidth: "800px", height: "auto", margin: "0px auto", width: "100%" }}
              />
              <div className="sec-detail">
                與上航科技合作開發消防圖控系統，可連接消防受信總機，擴展受信機資訊顯示能力。
                <br />
                功能：
                <br />
                <span style={{ color: "var(--color-accent)" }}>▶</span> 依照不同使用者權限登入，區分檢視和編輯功能
                <br />
                <span style={{ color: "var(--color-accent)" }}>▶</span> 檢視受信總機歷史訊息
                <br />
                <span style={{ color: "var(--color-accent)" }}>▶</span> 檢視所有端末裝置資訊及狀態
                <br />
                <span style={{ color: "var(--color-accent)" }}>▶</span> 查看故障設備及警報狀態
                <br />
                <span style={{ color: "var(--color-accent)" }}>▶</span> 自行定義大樓及樓層資訊，包含名稱、數量、樓層順序、樓面圖、每層樓端末裝置等
                <br />
                <span style={{ color: "var(--color-accent)" }}>▶</span> 小地圖檢視，方便操作樓層面積大的樓面圖
                <br />
                展示影片：
                <YoutubeEmbed embedId="HbE5dcNsegY" />
              </div>
            </div>
            <div className="divider" />
            <div className="teeth-white-sec sec-container">
              <div className="sec-title">牙齒美白</div>
              <div className="sec-detail">
                與OUNSS Design Solution合作開發牙齒美白機台，結合販賣機與自助牙齒美白機台，提供一站式牙齒美白服務。
                <br />
                <span style={{ color: "var(--color-accent)" }}>▶</span> 使用Wordpress架設管理介面，由Google Cloud Platform託管。
                <br />
                開發兩套桌面視窗程式，分別位於機台內和外：
                <br />
                <span style={{ color: "var(--color-accent)" }}>▶</span>{" "}
                機台內程式結合紅外線感測器和攝影機，提供互動式的介面讓使用者依照指示自行控制流程
                <br />
                <span style={{ color: "var(--color-accent)" }}>▶</span> 機台外程式提供使用者登入、購物等功能，並串接第三方金流付款
                <br />
                <a
                  href="https://www.ounss.com/projectDetail/DESIGN-STRATEGY/UI-UX-for-AT"
                  target="_blank"
                  rel="noreferrer noopener"
                  aria-label="external-link"
                  style={{ textalign: "center", textjustify: "center" }}
                >
                  詳細程式畫面請見OUNSS網站{" "}
                  <span style={{ fontSize: "1.6rem" }} class="material-symbols-outlined">
                    open_in_new
                  </span>
                </a>
              </div>
            </div>
            <div className="divider" />
            <div className="pet-manage-sec sec-container">
              <div className="sec-title">寵物店家管理系統</div>
              <img
                src="/pet-manage.webp"
                alt="pet-manage"
                style={{ display: "block", maxWidth: "800px", height: "auto", margin: "0px auto", width: "100%" }}
              />
              <div className="sec-detail">
                協助寵物飼養店家管理寵物資訊，包含寵物基本資料、配種紀錄、親代與子代關係。
                <br />
                <span style={{ color: "var(--color-accent)" }}>▶</span> 寵物清單 - 管理所有寵物的詳細資訊。
                <br />
                <span style={{ color: "var(--color-accent)" }}>▶</span>{" "}
                重要日期提醒 - 以日曆顯示待產日及疫苗施打日期。
                <br />
                <span style={{ color: "var(--color-accent)" }}>▶</span> 配種清單 - 記錄所有曾經配種過的寵物，並能簡單的新增後代。
                <br />
                <span style={{ color: "var(--color-accent)" }}>▶</span> 族譜樹 - 顯示每隻寵物的上一代及下一代，寵物之間的關係一目了然。
                <br />
                <span style={{ color: "var(--color-accent)" }}>▶</span> 買賣紀錄管理 - 記錄每一筆交易，一鍵下載買賣契約。
                <br />
                <a
                  href="https://petmanage.budylon.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                  aria-label="external-link"
                  style={{ textalign: "center", textjustify: "center" }}
                >
                  前往寵物管理首頁{" "}
                  <span style={{ fontSize: "1.6rem" }} class="material-symbols-outlined">
                    open_in_new
                  </span>
                </a>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Portfolio;
